import { onReady } from './utils/onReady';
import { setupLazyLoading } from './utils/lazyImage';
import { initVhUnitOverwrite, debounce } from './utils/helpers';
import { setupAnchorLinkScroll } from './components/anchors';
import { watchFormFields } from './components/form';

// Below are example of how to use import libraries installed via npm.
// import sal from 'sal.js';

function init() {
    document.body.classList.remove('standby');

    setupAnchorLinkScroll();

    onReady(() => {
        initVhUnitOverwrite();
    });

    window.addEventListener('load', () => {
        // Polyfill for using svg spritesheet in oldIE
        svg4everybody();
    });
}

init();
