import { getElementPosition } from './elementProperties';

const easeInOutQuad = function(currentTime, start, change, duration) {
    let time = currentTime;
    time /= duration / 2;
    if (time < 1) {
        return (change / 2) * time * time + start;
    }
    time--;
    return (-change / 2) * (time * (time - 2) - 1) + start;
};

const easeInOutQuintic = function(currentTime, start, change, duration) {
    let time = currentTime;
    const ts = (time /= duration) * time,
        tc = ts * time;
    return start + change * (6 * tc * ts + -15 * ts * ts + 10 * tc);
};

export function scrollTo(to, duration = 1500, callback) {
    function move(amount) {
        if (document.scrollingElement) {
            document.scrollingElement.scrollTop = amount;
        } else {
            document.documentElement.scrollTop = amount;
            document.body.parentNode.scrollTop = amount;
            document.body.scrollTop = amount;
        }
    }
    function position() {
        return document.documentElement.scrollTop || document.body.parentNode.scrollTop || document.body.scrollTop;
    }

    const start = position(),
        change = to instanceof Element ? getElementPosition(to).top - start : to - start,
        increment = 20;


    let currentTime = 0;

    const animate = function() {
        // increment the time
        currentTime += increment;
        // find the value with the quadratic in-out easing function
        const val = easeInOutQuintic(currentTime, start, change, duration);
        // move the document.body
        move(val);
        // do the animation unless its over
        if (currentTime < duration) {
            requestAnimationFrame(animate);
        } else {
            if (callback && typeof callback === 'function') {
                // the animation is done so lets callback
                if (typeof callback === 'function') {
                    callback();
                }
            }
        }
    };
    animate();
}
